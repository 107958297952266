export const silverWhiteList = [
    0x6db001325fd02a594e4af501152f399e031e68eb,
    0x1f5b653c13c24039fee32b4fd4a10e60bb9b9525,
    0xd71e012511b43eb3881b9ec1bf03c5ec1100d24b,
    0x5bd91f421178ff1b945b3d713c5dcda24aa7abc9,
    0xd569e88310f560a41c22e85eb9bd93eb1fc2adae,
    0x632e1aed97ef00f353b2a3b9e3b85c9998124144,
    0xadde33fcc054bfa3b59c489a01d58a2da51d0248,
    0x218f10290dd75c579298108b627ad14ca4bc14af,
    0x6f7b7faed5951209397ead4f372e7672c13ee4c7,
    0x518d52e1e420ac179cab38e61193395324e14de8,
    0x327b6a81af97f3c648fdd0154777d779938a6dad,
    0x781dd6d2cceb234101d2c7ed48768ecbc6c7e49f,
    0xf41c9011cf8213c741b3ab0464d069dd2b51d785,
    0xb921d1bd34a40e4f8330e617436d7bbf321efe47,
    0x6783b1261e19e8e682f25fda65374ddbfbc01ca3,
    0x577dec73965b03792b13f0efe28ef8df2b89c316,
    0xf540ed4c60aace9e2239daefc6503ca7faba048b,
    0x2d0856036d730d640d515804af6966cac26813b6,
    0xc59647c3f5e8ed48c500dcba5fc01b6562dc93e5,
    0x00c52351edd36b4e517359f1507f2eba77b7dc92,
    0xc5b8f9326813d42223005966c7322c79fe331ea3,
    0x7c9a9f533752316804598933daccedb9d6ec33b8,
    0xfd9a3b43c5d3327675f689c954d063dc3e60ecc0,
    0x3457e00ddcd69236f454c0b688c2fcf1b425af24,
    0x7321f286ed34dc4780291a3532c178c49655b3e8,
    0x0d24969d26177f813771eca05f819fb3e00210f8,
    0x0d24969d26177f813771eca05f819fb3e00210f8,
    0xf540ed4c60aace9e2239daefc6503ca7faba048b,
    0x012be54c3df74bcbe4d747f810eeaa5b2c64f996,
    0xc95d16a89a9c650790c7930e3b9a42f1a62c9a0a,
    0xb1eae6a8d4fefbe0ef1849c6237a0b80b0495f6d,
    0x2ef94b5223d76f58f939fb11b30ed531ddf0caf6,
    0xd05f74da1572bf6be8ebed552907b137579196cd,
    0x7cf3c2061a3eacc5f44eba2a9994d30863a4dd90,
    0xb27f2bdf6556502838eb6a0c829c1314021bd343,
    0x1ab7990c92364a82bc96bcfb51a4b97dc80ecf26,
    0x799e4f6defc8f2bd600cdd9c781224f0f0898d6d,
    0x3213f446f31d5054568d866e685e15450f6ecc7f,
    0x15ff734a62a7e301958021b473137375df067d0d,
    0xb991175200a225f124c7bb820751411144d03552,
    0xb1f7b329a8263c17f7b4ee5daa164972c0c4ba0e,
    0xe5a88853dd5e8ded51e818e1d6e59eeff1a5fdd6,
    0xf3e54F58985d23e0c8F46DeFd400045C6E72037d,
    0xd35d375b10e37af17a7557e92Fe6D583E20f0A29,
    0xcc4F4EFE9B2C50427fEA0123063E839D19E5BA89,
    0xc59647C3F5e8ED48c500DcBa5fC01b6562dC93E5,
    0x3213f446F31D5054568d866E685E15450F6eCC7F
  ];
  